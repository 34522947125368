.image-container {
  width: 100%; /* Set the desired width */
  overflow: hidden; /* Hide overflowing content */
}

.image-list {
  display: flex; /* Create a horizontal flex container */
  width: fit-content; /* Set the width to fit the total image width */
  animation: scroll-animation 10s linear infinite; /* Configure the animation */
}

.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel {
  overflow: hidden;
  width: 100%;
}

.carousel-inner {
  display: flex;
  /* transition: transform 0.1s linear; */
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 8px;
  border-radius: 50%;
  z-index: 1;
}

.carousel-arrow.left {
  left: 0;
}

.carousel-arrow.right {
  right: 0;
}


