@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Import Ant Design styles */
@import '~antd/dist/reset.css';


@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

body {
  font-family: 'Poppins', sans-serif;
}

.animation-fade-in-down {

  animation-name: fade-in-down;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
}

.whitespace-no-wrap {
  text-wrap: nowrap;
}
.ant-menu-item-selected::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  width: 5px; /* Adjust as needed */
  height: 5px; /* Adjust as needed */
  background-color: #808080; /* Adjust color as needed */
  border-radius: 50%;
}

.ant-menu-submenu-title {
  padding-left: 0px !important;
}

.ant-menu-sub a {
  padding-left: 24px !important;
  color: gray !important;
  /* font-weight: 100; */
}

.ant-dropdown.css-dev-only-do-not-override-1qhpsh8.ant-dropdown-placement-top {
  /* height: 50vh !important; */
  overflow: scroll !important;

}

.ant-dropdown-menu.ant-dropdown-menu-root {
  background-color: #fbfaff !important;
}

.Review {
  overflow: hidden;
  /* margin:30px; */
  /* padding-top:'10px'; */
  /* padding-bottom: '10px'; */
}

.numbered-list {
  counter-reset: item;
  /* Reset the counter for the list */
  list-style-type: none;
  /* Remove default numbering */
  padding-left: 0;
  /* Remove default padding */
}

.numbered-list li {
  counter-increment: item;
  /* Increment the counter for each list item */
  margin-bottom: 10px;
  /* Adjust spacing between items */
}

.numbered-list li::before {
  content: counter(item) ".";
  /* Display the counter number followed by a period */
  font-weight: normal;
  /* Make the counter bold */
  margin-right: 5px;
  /* Adjust spacing between the counter and text */
}

.card {
  width: 500px;
  height: 'auto';
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(158, 151, 151, 0.25);
  border-radius: 15px;
  margin: 12px;
  padding: 12px;
}

.d-flex {
  display: flex;
  transform: translateX(calc(100vw));
  overflow: visible;
  animation: animateContainer 40s linear forwards infinite;
  animation-play-state: paused;
}

@keyframes animateContainer {
  from {
    transform: translateX(calc(100vw));
  }

  to {
    transform: translateX(calc(1%));
  }
}

/* .ant-modal-content {
  padding: 0 !important;
} */

.upgradedEffect::before {
  display: block;
  padding: 10px;
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsla(0, 0%, 100%, .2) var(--tw-gradient-via-position), var(--tw-gradient-to);
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  animation: shine 3s infinite;
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset: 0;
  position: absolute;
}

@keyframes shine {
  100% {
    content: var(--tw-content);
    transform: translateX(100%);
  }
}

.ck-blurred,
.ck-focused {
  padding-left: 20px !important;
}

.ql-aitool {
  width: 100px;
}


.ql-aitool .ql-picker-item:before {
  content: attr(data-label);
}

.idea-text span {
  display: none;
}

.idea-text:hover span {
  display: block;
}

.idea-text:hover {
  background-color: rgb(212, 213, 214);
}

.layout-dashboard .ant-layout-sider.sider-primary {
  margin: 0px !important;
  padding: 13px 20px;
  height: calc(100vh - 20px);
}

.revenue-action {
  display: none;
}

.revenue:hover a {
  display: block;
}

.revenue:hover span {
  display: block;
}

.revenue span {
  display: none;
  margin-left: 5px;
}

.company .hiddenItem {
  display: none;
}

.company:hover {
  background-color: rgb(232, 228, 228);
}

.company:hover .hiddenItem {
  display: block;
}

.revenue button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-edit-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
}

.sidebar-edit-btn a:hover {
  background-color: rgb(232, 232, 232)
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 0;
}

.ql-bubble .ql-editor h1 {
  font-size: 24px;
  /* margin-top: 1.6em; */
  /* margin-bottom: 0.6em; */
  color: #1C1917;
  line-height: 1.333;
  font-weight: 700;
}

.ql-bubble .ql-editor h2 {
  font-size: 20px;
  color: #1C1917;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
  font-weight: 600;
}

.ql-bubble .ql-editor h4 {
  font-size: 20px;
  color: #1C1917;
  /* margin-top: 1.6em;
  margin-bottom: 0.6em; */
  line-height: 1.6;
  font-weight: 600;
}

.ql-bubble .ql-editor h3 {
  font-size: 16px;
  line-height: 1.75;
  font-weight: 100;
  color: inherit;
}

.ql-bubble .ql-editor p {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.75;
  font-weight: 100;
  color: inherit;
}

.ql-bubble .ql-editor h5 {
  font-family: 'Poppins', sans-serif;
  line-height: 1.75;
  margin: inherit;
  /* Inherit margin from parent element */
  color: inherit;
  /* Inherit color from parent element */
  letter-spacing: 0.5px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 100;
}

.ql-bubble .ql-editor h6 {
  color: black;
  font-size: 16px;
  line-height: 1.75;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media print {
  .ant-layout.css-dev-only-do-not-override-1qhpsh8 {
    /* display: none; */
    margin-left: 0px;
  }

  .ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
    display: none;
  }
}

.ql-editor ul {
  margin-left: 10px;

}

.ql-bubble .ql-editor li {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px
}

.ql-editor ul li::before {
  color: #d6d3d1;
  /* Change the color of the bullet points */
}

.ql-editor ul li::before {
  padding-right: 13px;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0;
}

.ant-color-picker-trigger .ant-color-picker-color-block {
  position: relative;
  border-radius: 4px;
  width: 90%;
  height: 80px;
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.25);
  background-image: conic-gradient(rgba(0, 0, 0, 0.06) 0 25%, transparent 0 50%, rgba(0, 0, 0, 0.06) 0 75%, transparent 0);
  background-size: 50% 50%;
}

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-avatar {
  box-sizing: border-box;
  border: 1px solid #e5e7eb;
  padding: 10px 10px;
  margin-inline-end: 16px;
}

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  line-height: 1.5714285714285714;
}

.ql-bubble .ql-editor strong {
  font-size: 16px;
  color: #1C1917;
}

.ql-bubble .ql-editor b {
  font-size: 16px;
}

#components-popconfirm-demo-placement .ant-btn {
  margin-left: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  width: 70px;
  text-align: center;
  padding: 0;
}

#components-popconfirm-demo-placement .ant-btn-rtl {
  margin-left: 8px;
  margin-right: 0;
}

.anticon {
  display: inline-flex;
  /* margin-right: 5px; */
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Container_Container__N8lVt {
  display: flex;
  flex-direction: column;
  grid-auto-rows: max-content;
  overflow: hidden;
  /* box-sizing: border-box; */
  appearance: none;
  outline: none;
  min-width: 400px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 10px;
  border-radius: 5px;
  min-height: 0px !important;
  transition: background-color 350ms ease;
  background-color: rgba(246, 246, 246, 1);
  border: none !important;
  font-size: 1em;
  font-weight: 500;
}

.Container_Container__N8lVt ul {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(var(--columns, 1), 1fr);
  list-style: none;
  padding: 10px;
  margin: 0;
  background-color: white;
  /* overflow-x: hidden; */
}

.Container_Container__RQaXK {
  display: flex;
  flex-direction: column;
  grid-auto-rows: max-content;
  overflow: hidden;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  min-width: 335px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 10px;
  border-radius: 5px;
  min-height: 100px;
  transition: background-color 350ms ease;
  background-color: rgba(246, 246, 246, 1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 1em;
  font-weight: 500;
}

.Container_Container__RQaXK ul {
  background-color: white;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(var(--columns, 1), 1fr);
  list-style: none;
  padding: 5px 20px 20px 20px !important;
  margin: 0;
}

.Container_Header__y0gXD {
  display: flex;
  padding: 10px 20px;
  /* padding-right: 8px; */
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none !important;
}

.gradient-button {
  background-color: #4382FE;
  background-image: linear-gradient(to bottom right, #4382FE, #314EF4);
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.gradient-button:hover {
  background: linear-gradient(to bottom right, #314EF4, #4382FE);
}

:where(.css-dev-only-do-not-override-1qhpsh8).ant-modal .ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.88);
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item svg path,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu svg path {
  fill: none !important;
}

:where(.css-dev-only-do-not-override-1qhpsh8).ant-drawer .ant-drawer-close {
  display: table-row-group;
  margin-inline-end: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.2s;
  text-rendering: auto;
  position: fixed;
  right: 10px;
}

:where(.css-dev-only-do-not-override-1qhpsh8).ant-drawer .ant-drawer-title {
  flex: 1;
  margin: 0;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
}

:where(.css-1qhpsh8).ant-dropdown {
  height: 50vh !important;
  overflow: scroll !important;
}

/* :where(.css-dev-only-do-not-override-1qhpsh8) a {
  color: #1677ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
} */