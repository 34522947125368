.Wrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  justify-content: flex-start;
  overflow: hidden;

  &.center {
    justify-content: center;
  }
}
