.main-admin {
    max-width: 1020px;
    margin: 0rem auto;
    padding: 0.5rem 1rem;
}

.main-admin h3 {
    font-size: 2rem;
}

.title h1, .title h2 {
    font-size: 1rem;
    padding-top: 1rem
}

.prompt {
    width: 100%;
    /* margin-top: 1rem; */
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: #e6e6e6;
    resize: none;
    overflow-y: hidden;
}

.info {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-color: #41403e;
    border-color: var(--primary);
    border-style: solid;
    border-width: 2px;
}

.item-board {
    border: 1px solid #cdcccb;
    box-shadow: -1px 5px 35px -9px #0003;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 2rem;
}